import React, { useEffect, useRef, useState } from 'react';
import './ApiPage.scss';
import { useAppSelector } from '../../app/hooks';
import { customerDetailsSelector } from '../../features/customerSlice';
import { Route, useHistory, useLocation } from 'react-router-dom';
import ApiQuotePage from './api-quote-page/ApiQuotePage';
import { scrollToElement } from '../../utilities/HelperUtilities';
import { AMIFormElement, AMIInput, Icon } from '../../ui-components/UiComponents';
import { quoteData } from './quote-data';
import ApiAuthPage from './api-auth-page/ApiAuthPage';
import ApiBookingPage from './api-booking-page/ApiBookingPage';
import { bookingData } from './booking-data';
import ReferenceDataPage from './ancillary-pages/ReferenceData';
import ApiTrackingPage from './api-tracking-page/ApiTrackingPage';
import { trackingData } from './tracking-data';
import { labelData } from './label-data';
import ApiLabelPage from './api-label-page/ApiLabelPage';

const ApiPage = () => {

  const history = useHistory();
  const location = useLocation().pathname;
  const customer = useAppSelector(customerDetailsSelector);
  const searchInputRef = useRef<any>(null);
  const [examplesHidden, setExamplesHidden] = useState(false);
  const [parsedQuoteData, setParsedQuoteData] = useState<any>();
  const [parsedTrackingData, setParsedTrackingData] = useState<any>();
  const [parsedBookingData, setParsedBookingData] = useState<any>();
  const [parsedLabelData, setParsedLabelData] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>('');
  const contractChevron = <svg className="api-grid__table__chevron" viewBox="0 0 24 24" x="0" y="0"><polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "></polygon></svg>;
  const expandChevron = <svg style={{transform: 'rotate(180deg)'}} className="api-grid__table__chevron" viewBox="0 0 24 24" x="0" y="0"><polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "></polygon></svg>;

  const [currentApiElement, setCurrentApiElement] = useState<any>();

  useEffect(() => {
    setParsedQuoteData(JSON.parse(quoteData));
    setParsedTrackingData(JSON.parse(trackingData));
    setParsedBookingData(JSON.parse(bookingData));
    setParsedLabelData(JSON.parse(labelData));
  }, [])

  const onHideExamplesClick = () => {
    setExamplesHidden(!examplesHidden);
    const exampleElementsArray = Array.from(document.getElementsByClassName('api-grid__example')) as HTMLElement[];
    const apiGridArray = Array.from(document.getElementsByClassName('api-grid')) as HTMLElement[];
    exampleElementsArray.forEach(element => {
      if (element.style.display === 'none') element.style.display = 'revert';
      else element.style.display = 'none';
    })

    if (exampleElementsArray[0].style.display === 'none') {
      apiGridArray.forEach(element => {
        element.style.gridTemplateColumns = '1fr';
      })
    } else {
      apiGridArray.forEach(element => {
        element.style.gridTemplateColumns = 'revert';
      })
    }
  }

  const onClearSearch = () => {
    searchInputRef.current.value = '';
    setSearchValue('');
    setCurrentElIndex(0);
    setFoundElements([]);
  }

  const [foundElements, setFoundElements] = useState<any>([]);
  const [currentElIndex, setCurrentElIndex] = useState<number>(0);

  const handleScrolling = (index: number) => {
    if (foundElements) foundElements[index]?.scrollIntoView({block: 'center'});
  }

  useEffect(() => {
    const search = setTimeout(() => {
      if (searchValue.length > 2) {
        const foundRows = Array.from(document.getElementsByClassName('api-table-row__found')) as HTMLElement[];
        if (foundRows.length > 0) {
          setFoundElements(foundRows);
        }
      } else {
        setFoundElements([]);
        setCurrentElIndex(0);
      }
    }, 300)

    return () => clearTimeout(search);
  }, [searchValue])

  useEffect(() => {
    if (foundElements.length > 0) {
      setCurrentApiElement(foundElements[currentElIndex]);
      handleScrolling(currentElIndex);
    }
  }, [foundElements])

  const onNextElementClick = () => {
    let index = currentElIndex;
    if (currentElIndex < foundElements.length - 1) index++;
    else index = 0;
    handleScrolling(index);
    setCurrentElIndex(index);
    setCurrentApiElement(foundElements[index]);
  }

  const onPreviousElementClick = () => {
    let index = currentElIndex;
    if (currentElIndex > 0) index--;
    else index = foundElements.length - 1;
    handleScrolling(index);
    setCurrentElIndex(index);
    setCurrentApiElement(foundElements[index]);
  }

  return (
    <div className="api-page">
      <header>
        <img
          src={
            customer.customerCountryCode === 'US'
            ? 'images/q2s-logo.png'
            : 'images/c2s-logo.png'
          }
          alt="Logo"
        ></img>

        <h1>API Documentation</h1>
      </header>

      <div
        className="hide-examples-button"
        onClick={onHideExamplesClick}
      >
        <div className="hide-examples-button__chevron">
          {examplesHidden ? expandChevron : contractChevron}
        </div>
          {examplesHidden ? "Show Examples" : "Hide Examples"}
        <div className="hide-examples-button__chevron">
        {examplesHidden ? expandChevron : contractChevron}
        </div>
      </div>

      <nav>
        <div className="nav-links">
          <div
            className={
              location === "/api"
                ? "nav-item nav-item--active"
                : "nav-item"
            }
            onClick={() => history.push('/api')}
          >
            About
            <div className={
              location === "/api/about"
                ? "nav-item__sub-menu--active"
                : "nav-item__sub-menu"
            }
            >
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("about-header-schema")}>
                Header
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("about-body-schema")}>
                Body
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("about-response-success-schema")}>
                Success Response
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("about-response-error-schema")}>
                Error Response
              </div>
            </div>
          </div>

          <div
            className={
              location === "/api/auth"
                ? "nav-item nav-item--active"
                : "nav-item"
            }
            onClick={() => history.push('/api/auth')}
          >
            Auth
            <div className={
              location === "/api/auth"
                ? "nav-item__sub-menu--active"
                : "nav-item__sub-menu"
            }
            >
            </div>
          </div>

          <div
            className={
              location === "/api/quote"
                ? "nav-item nav-item--active"
                : "nav-item"
            }
            onClick={() => history.push('/api/quote')}
          >
            Quote
            <div className={
              location === "/api/quote"
                ? "nav-item__sub-menu--active"
                : "nav-item__sub-menu"
            }
            >
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("quote-header-schema")}>
                Header
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("quote-body-schema")}>
                Body
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("quote-response-success-schema")}>
                Success Response
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("quote-response-error-schema")}>
                Error Response
              </div>
            </div>
          </div>

          <div
            className={
              location === "/api/booking"
                ? "nav-item nav-item--active"
                : "nav-item"
            }
            onClick={() => history.push('/api/booking')}
          >
            Booking
            <div className={
              location === "/api/booking"
                ? "nav-item__sub-menu--active"
                : "nav-item__sub-menu"
            }
            >
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("booking-header-schema")}>
                Header
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("booking-body-schema")}>
                Body
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("booking-response-success-schema")}>
              Success Response
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("booking-response-error-schema")}>
                Error Response
              </div>
            </div>
          </div>

          <div
            className={
              location === "/api/tracking"
                ? "nav-item nav-item--active"
                : "nav-item"
            }
            onClick={() => history.push('/api/tracking')}
          >
            Tracking
            <div className={
              location === "/api/tracking"
                ? "nav-item__sub-menu--active"
                : "nav-item__sub-menu"
            }
            >
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("tracking-header-schema")}>
                Header
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("tracking-response-success-schema")}>
                Success Response
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("tracking-response-error-schema")}>
                Error Response
              </div>
            </div>
          </div>

          <div
            className={
              location === "/api/label"
                ? "nav-item nav-item--active"
                : "nav-item"
            }
            onClick={() => history.push('/api/label')}
          >
            Label
            <div className={
              location === "/api/label"
                ? "nav-item__sub-menu--active"
                : "nav-item__sub-menu"
            }
            >
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("label-header-schema")}>
                Header
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("label-response-success-schema")}>
                Success Response
              </div>
              <div className="nav-item__sub-menu__item" onClick={() => scrollToElement("label-response-error-schema")}>
                Error Response
              </div>
            </div>
          </div>
        </div>

        <div className="api-search">
          <AMIFormElement label="API Search">
            <AMIInput
              refFn={searchInputRef}
              name="apiSearch"
              placeholder="Search..."
              onChange={(event) => setSearchValue(event.target.value.toLowerCase())}
            />
          </AMIFormElement>
          <div className="api-search__clear" onClick={onClearSearch}>X</div>
          {foundElements.length > 0 && (
            <div className="api-search__found-el-cont">
              <Icon
                name="ChevronLeft"
                style={{transform: "translateY(2px)"}}
                onClick={onPreviousElementClick}
              />
              <p>Found: {currentElIndex + 1} / {foundElements.length}</p>
              <Icon
                name="ChevronRight"
                style={{transform: "translateY(2px)"}}
                onClick={onNextElementClick}
              />
            </div>
          )}
        </div>
      </nav>

      <main>
        <Route path="/api/auth">
          <ApiAuthPage/>
        </Route>

        <Route path="/api/quote">
          <ApiQuotePage
            data={parsedQuoteData?.quote}
            searchValue={searchValue}
            currentApiElement={currentApiElement}
          />
        </Route>

        <Route path="/api/tracking">
          <ApiTrackingPage
            data={parsedTrackingData?.tracking}
            searchValue={searchValue}
            currentApiElement={currentApiElement}
          />
        </Route>

        <Route path="/api/booking">
          <ApiBookingPage
            data={parsedBookingData?.booking}
            searchValue={searchValue}
            currentApiElement={currentApiElement}
          />
        </Route>

        <Route path="/api/label">
          <ApiLabelPage
            data={parsedLabelData?.label}
            searchValue={searchValue}
            currentApiElement={currentApiElement}
          />
        </Route>

        <Route path="/api/reference-data">
          <ReferenceDataPage />
        </Route>
      </main>
    </div>
  )
}

export default ApiPage;